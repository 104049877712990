body {
  margin: 0;
  font-family: "Ideal Sans", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mantine-Radio-labelWrapper,
.mantine-Stepper-step,
.mantine-Stepper-stepLabel,
.mantine-Text-root,
.mantine-Button-label,
.mantine-Input-input,
.mantine-Alert-label,
.mantine-Alert-message,
.mantine-8od8ev,
.mantine-ye2q1m,
p,
label {
  font-family: "Ideal Sans";
  font-size: 16px;
}

p, ul {
  color: var(--gris);
}

code {
  font-family: "Ideal Sans", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* - - css custom - - */
:root {
  --main-color: #0B7EA9;
  --secondary-color: #1D1D1B;
  --default-color: #ced4da;
  --default-bg-color: #f8f9fa;
  --almond: #D3A98A;
  --gris: #7A7A7A;
  --obsidian: #1D1D1B
}

.mantine-e09yy6,
.mantine-1xskx51,
.hover:hover,
.mantine-1wjygiw:checked,
.mantine-jbn1md:checked,
.mantine-Select-item:active {
  background-color: var(--main-color);
}

.mantine-1wjygiw:checked,
.mantine-jbn1md:checked,
.mantine-ye2q1m:focus,
.mantine-ye2q1m:focus-within {
  border-color: var(--main-color);
}

.mantine-11wgasr {
  padding: 5px 20px;
}

.main-color,
.icon {
  color: var(--main-color);
}

@media (min-width: 767px) {
  .mantine-Image-root.image-menu .mantine-3y8yz3 {
    height: 50px !important;
  }
}

.mantine-Container-root h1 {
  padding: 3rem 0 4rem 0;
  font-family: "PlayFair Display", sans-serif;
  color: var(--main-color);
  font-weight: bold;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.mantine-Container-root h2 {
  color: var(--obsidian);
  font-weight: 600;
  font-size: 1.25rem;
}

.mantine-Container-root h3 {
  padding: 3rem 0 1rem 0;
  font-family: "Ideal Sans", sans-serif;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.mantine-Container-root h4 {
  padding: 1rem 0;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

.mantine-Container-root h6 {
  padding: 0.5rem 0;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1.25rem;
}

.reinsurance-block {
  margin: -400px 20px 10px 20px;
  padding: 35px;
  font-weight: bold;
}

@media (max-width: 1199px) {
  .reinsurance-block {
    margin: 20px;
  }
}

.mantine-m53d0u {
  background: var(--main-color);
}

.top-card-title {
  color: white;
  background-color: var(--main-color);
  padding: 20px 0;
  font-weight: bold;
}

.card-date-title {
  color: var(--main-color);
  padding: 40px 0;
  font-weight: bold;
}

/* Filtres pour le choix de nombre de jours de cures pas home */
.night-nav-active {
  border: 1px solid var(--main-color);
  color: white;
  text-transform: uppercase;
  background-color: var(--main-color);
  font-size: small;
}

@media (min-width: 767px) {
  .night-nav:hover {
    border: 1px solid var(--main-color);
    color: white;
    text-transform: uppercase;
    background-color: var(--main-color);
    font-size: small;
  }
}

@media (min-width: 767px) {
  .night-nav-active:hover {
    border: 1px solid var(--main-color);
    color: var(--main-color);
    text-transform: uppercase;
    background-color: white;
    font-size: small;
  }
}

.night-nav {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  text-transform: uppercase;
  background-color: white;
  font-size: small;
}

/* {
  border: 1px solid var(--main-color);
  color: white;
  background-color: var(--main-color);
} */
.date-nav {
  background-color: var(--main-color);
  color: white;
}

.main-text {
  color: var(--main-color) !important;
  font-weight: bold;
}

.main-detail {
  color: var(--obsidian);
  /* font-weight: bold; */
}

.filter {
  font-size: small;
}

.main-title-items {
  padding: 20px 0;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1.85rem;
  line-height: 1.2em;
}

.cure-detail-title,
.cure-detail-title span {
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.2em;
}

.from-price {
  color: var(--main-color);
  font-size: 0.75rem;
  line-height: 1.55;
}

.price {
  color: var(--main-color);
  font-weight: bold;
  font-size: 1.25rem;
}

.recap-card-title {
  font-family: "Ideal Sans", sans-serif;
  color: var(--main-color);
  font-size: 1.25rem;
  line-height: 1em;
}

.recap-card-text {
  color: var(--secondary-color);
  font-weight: bold;
}

/* - CureSection - */
.curesection-block {
  border-top: 1px solid var(--default-color);
  
  border-radius: inherit;
}

@media (min-width: 1199px) {
  .curesection-block {
    padding: 40px;
  }

}

.mantine-p6kgvj {
  border: 1px solid var(--default-color);
  box-shadow: none;
}

.mantine-4ny9bc {
  top: 4%;
  /* Position verticale */
  transform: translateY(-50%);
}

.mantine-2yup0d {
  color: var(--main-color) !important;
  border: none !important;
  box-shadow: none !important;
}

/* - fin CureSection - */
/* - stepper - */
.mantine-sosrlp {
  color: var(--main-color);
  border: 2px solid var(--main-color);
  font-size: 14px;
  background-color: white;
}

.mantine-lpco65,
.mantine-14w24h {
  margin-top: -45px;
  margin-left: 0;
  margin-right: 0;
}

.stepper-block-active {
  height: 160px;
  padding: 20px 15px 0 15px;
  margin-bottom: -37px;
  color: white;
  font-weight: bold;
  background-color: var(--main-color);
  clip-path: polygon(0 0, 100% 0%, 100% 87%, 70% 87%, 50% 100%, 30% 87%, 0 87%);
}

.stepper-block-inline {
  height: 160px;
  padding: 20px 15px 0 15px;
  margin-bottom: -37px;
  color: var(--main-color);
  font-weight: bold;
}

.stepper-block-inert {
  height: 160px;
  padding: 20px 15px 0 15px;
  margin-bottom: -37px;
  color: var(--main-color);
  opacity: 0.5;
  font-weight: bold;
}

.mantine-fud2mt,
.mantine-el80o3 {
  flex-direction: column;
  width: 100px;
}

.mantine-gyaa8m {
  margin-top: 12px;
  margin-left: 0;
  text-align: center;
}

.mantine-1nvuvu1 {
  font-size: 12px;
  text-align: center;
}

.text-center {
  text-align: center;
}

/* - fin stepper - */
/* - step - */
.select-zone {
  padding: 0 20px;
  border: 1px solid var(--default-color);
}

.step-block {
  padding: 10px;
  background-color: var(--default-bg-color);
}

.mantine-Radio-root .mantine-Radio-label {
  margin-top: -2px;
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 1rem;
}

.step-btn-previous {
  color: var(--default-color);
  font-weight: bold;
  background-color: transparent;
}

.step-btn-previous:hover {
  color: var(--main-color);
  font-weight: bold;
  text-decoration: underline;
  background-color: transparent;
}

.step-btn-next {
  color: var(--main-color);
  font-weight: bold;
  background-color: transparent;
}

.step-btn-next:hover {
  color: var(--main-color);
  font-weight: bold;
  text-decoration: underline;
  background-color: transparent;
}

.step-text-confirmation {
  color: red;
}

@media (max-width: 767px) {
  .mantine-15v1h2n {
    display: none;
  }
}

/* - fin step - */

.fixed-date-carousel {
  position: sticky;
  top: 0;
  z-index: 10;
}

.margin-y-0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.padding-10 {
  padding: 10px;
}

.card_date {
  position: sticky;
  top: 420px;
  z-index: 10;
  width: 100%;
}

.header-summary {
  font-size: 1.17em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

summary {
  margin-bottom: 10px;
}