@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

@font-face {
    font-family: "Ideal Sans";
    src: url("fonts/Ideal_Sans/IdealSans-Light-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-style: italic;
    src: url("fonts/Ideal_Sans/IdealSans-LightItalic-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-weight: 500;
    src: url("fonts/Ideal_Sans/IdealSans-Medium-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-weight: 500;
    font-style: italic;
    src: url("fonts/Ideal_Sans/IdealSans-MediumItalic-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-weight: 600;
    src: url("fonts/Ideal_Sans/IdealSans-Semibold-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-weight: 600;
    font-style: italic;
    src: url("fonts/Ideal_Sans/IdealSans-SemiboldItalic-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-weight: 700;
    src: url("fonts/Ideal_Sans/IdealSans-Bold-Pro.otf");
}

@font-face {
    font-family: "Ideal Sans";
    font-weight: 700;
    font-style: italic;
    src: url("fonts/Ideal_Sans/IdealSans-BoldItalic-Pro.otf");
}